body {
  margin: 0;
  font-family: 'Roboto', 'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* @font-face {
  font-family: Helvetica;
  src: url('./assets/font/helvetica-light-587ebe5a59211.ttf');
}

@font-face {
  font-family: Helvetica;
  src: url('./assets/font/Helvetica.ttf');
  font-weight: 500;
}

@font-face {
  font-family: Helvetica;
  src: url('./assets/font/Helvetica-Bold.ttf');
  font-weight: bold;
}

@font-face {
  font-family: Helvetica;
  src: url('./assets/font/Helvetica-Bold.ttf');
  font-weight: 600;
}

@font-face {
  font-family: Helvetica;
  src: url('./assets/font/Helvetica-Bold.ttf');
  font-weight: 700;
} */

* {
  font-family: 'Roboto', 'sans-serif';
  box-sizing: border-box;
}